<template>
  <div class="dataviz">
    <!-- page title -->
    <page-title></page-title>

    <!-- dataviz header -->
    <v-container>
      <div class="dataviz-header py-4" v-html="intestazione"></div>

      <!-- dataviz body -->
      <v-row>
        <v-col
          class="col-12 col-md-4"
          v-for="dataViz in displayedDataViz"
          :key="dataViz.titolo"
        >
          <dataviz-card :dataViz="dataViz"></dataviz-card>
        </v-col>
      </v-row>

      <!-- show all btn -->
      <v-row class="justify-center py-16">
        <v-btn
          class="show-all-btn"
          v-if="dataVizList.length > shownNumber"
          @click="showMore"
          color="#2AB5B9"
          >carica altre</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import DatavizCard from "../components/DatavizCard";
import { getDataViz, getIntestazione } from "../services/api";
export default {
  name: "Dataviz",
  components: {
    PageTitle,
    DatavizCard,
  },
  data() {
    return {
      shownNumber: 9,
      intestazione: ""
    };
  },
  async created() {
    this.$emit("setLoading", true);

    let dataVizPromise = getDataViz();
    let intestazionePromise = getIntestazione("DATAVIZ");

    if (this.$store.getters["getDataVizList"].length == 0) {
      try {
        let { data } = await dataVizPromise;
        this.$store.dispatch("setDataVizList", {
          dataVizList: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    try {
      let { data } = await intestazionePromise;
      // decode special characters
      var txt = document.createElement("textarea");
      txt.innerHTML = data[0].intestazione;
      this.intestazione = txt.value;
    } catch (err) {
      console.log(err);
    }

    this.$emit("setLoading", false);
  },
  computed: {
    dataVizList() {
      return this.$store.getters["getDataVizList"] ?? [];
    },
    displayedDataViz() {
      return this.dataVizList.slice(0, this.shownNumber);
    },
  },
  methods: {
    showMore() {
      this.shownNumber += 3;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.dataviz {
  .dataviz-header {
    font: normal normal normal 21px/40px Open Sans;
    letter-spacing: 0px;
  }
}
</style>